import React, { useState, useEffect } from 'react'
import '../assets/portfolio.css'
import { motion } from 'framer-motion'
import img1 from '../images/portfolio/MOSES_preview.jpg'
import img2 from "../images/portfolio/FORTNITE_preview.jpg"
import img3 from '../images/portfolio/Reebok x MSCHF_preview.jpg'
import img4 from '../images/portfolio/BMW_XM.jpg'
import img5 from '../images/portfolio/COACHELLApreview.png'
import img6 from '../images/portfolio/H&M x DISNEY_preview.jpg'
import img7 from '../images/portfolio/T-Mobile.png'
import img8 from '../images/portfolio/FENTY.png'
import img9 from '../images/portfolio/Peter_fox.jpg'
import img10 from '../images/portfolio/Overwatch2.jpg'
import img11 from '../images/portfolio/PUMA_preview.png'
import img12 from '../images/portfolio/GL.png'
import img13 from '../images/portfolio/Pink_preview.png'
import img14 from '../images/portfolio/asics_x_bentgablenits_preview.jpg'
import img15 from '../images/portfolio/reel_preview.png'
import img16 from '../images/portfolio/Grimes_preview.png'
import img17 from "../images/portfolio/updates/Dua_X_Jennie_poster_16x9.jpg"
import img18 from "../images/portfolio/updates/LV_Mura_poster_16x9.jpg"
import img19 from "../images/portfolio/updates/SIA_poster.jpg"

import video1 from '../videos/moses_preview.mp4'
import video2 from '../videos/fortniteXweeknd_preview.mp4'
import video3 from '../videos/reebok_mschf_preview.mp4'
import video4 from '../videos/bmw_preview.mp4'
import video5 from '../videos/coachellaXyoutube_preview.mp4'
import video6 from '../videos/disneyXhm_preview.mp4'
import video7 from '../videos/t-mob_preview.mp4'
import video8 from '../videos/fentyXsavage_preview.mp4'
import video9 from '../videos/peter_fox_preview.mp4'
import video10 from '../videos/overwatch_preview.mp4'
import video11 from '../videos/pumaXmelo_preview.mp4'
import video12 from '../videos/galaries_lafayette_preview.mp4'
import video13 from '../videos/pinkpantheress_preview.mp4'
import video14 from '../videos/asics_preview.mp4'
import video15 from '../videos/ree_preview_ORIGINAL.mp4'
import video16 from '../videos/grimes_preview.mp4'
import video17 from "../videos/updates/Dua_X_Jennie_preview_16x9.mp4"
import video18 from "../videos/updates/LV_Mura_preview_16x9.mp4"
import video19 from "../videos/updates/SIA_preview.mp4"

import meta from '../store/meta'
import play from "../images/shared/showreel/play.svg";
import { isDesktop } from 'react-device-detect'

const Portfolio = () => {
  useEffect(() => {
    document.title = "HIMIA | Works"
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const [currentCategory, setCurrentCategory] = useState('ALL')
  const categories = ['ALL', 'MUSIC VIDEO', 'COMMERCIALS', 'REELS']
  const [hoveredId, setHoveredId] = useState(null)

  const works = [
    {
      name: 'David Guetta & Sia - Beautiful People',
      full: 'David Guetta & Sia - Beautiful People',
      img: img19,
      video: video19,
      url: 'https://player.vimeo.com/video/1065989688',
      director: ' Daniel Askill',
      services: ' VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'JENNIE & Dua Lipa - Handlebars',
      full: 'JENNIE & Dua Lipa - Handlebars',
      img: img17,
      video: video17,
      url: 'https://player.vimeo.com/video/1064446401',
      director: ' BRTHR',
      services: ' VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'MNNK Bro. (Takashi Murakami & JP THE WAVY) - LV MURAKAMI',
      full: 'MNNK Bro. (Takashi Murakami & JP THE WAVY) - LV MURAKAMI',
      img: img18,
      video: video18,
      url: 'https://player.vimeo.com/video/1065991979',
      director: ' BRTHR',
      services: ' VFX, CGi, Animation',
      category: 'MUSIC VIDEO'
    },

    {
      name: 'Asics x Bentgablenits - A Prize In Every Box',
      full: 'Asics x Bentgablenits - A Prize In Every Box',
      img: img14,
      video: video14,
      url: 'https://player.vimeo.com/video/984466984',
      client: 'Asics, Bentgablenits',
      director: ' Connor Brashier & Angelo Nitsopoulos',
      services: ' VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Moses Sumney - Vintage',
      full: 'Moses Sumney - Vintage',
      img: img1,
      video: video1,
      url: 'https://www.youtube.com/embed/CQdFOdyTGgc',
      // client: 'Moses Sumney - Vintage',
      director: 'Moses Sumney',
      services: 'VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'COACHELLA x YouTube',
      full: 'COACHELLA On YouTube',
      img: img5,
      video: video5,
      url: 'https://player.vimeo.com/video/938556780',
      client: 'Coachella, YouTube',
      director: 'BRTHR',
      services: 'VFX, CGI, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Fortnite x WEEKND',
      full: 'The Weeknd x Fortnite Festival Trailer',
      img: img2,
      video: video2,
      url: 'https://www.youtube.com/embed/jIDSHW5tIrQ?si=fq4-VVwboc-TK5yz',
      client: 'Epic Games',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'PUMA x MELO',
      full: 'PUMA x MELO MB.03',
      img: img11,
      video: video11,
      url: 'https://player.vimeo.com/video/938556195',
      client: 'PUMA',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Reebok x MSCHF',
      full: 'Reebok x MSCHF Pump Omni Zone LX',
      img: img3,
      video: video3,
      url: 'https://player.vimeo.com/video/867451990',
      client: 'Reebok',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'H&M x Disney',
      full: 'H&M x Disney',
      img: img6,
      video: video6,
      url: 'https://player.vimeo.com/video/854897793',
      client: 'H&M',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'T-Mobile',
      full: 'Summer of Joy, Youth, Freedom',
      img: img7,
      video: video7,
      url: 'https://player.vimeo.com/video/834369636',
      client: 'Deutsche Telekom',
      director: 'BRTHR',
      services: 'VFX, CGi, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'SAVAGE x FENTY By Rihanna',
      full: 'SAVAGE x FENTY By Rihanna',
      img: img8,
      video: video8,
      url: 'https://player.vimeo.com/video/792265712',
      client: 'SAVAGE x FENTY By Rihanna',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Peter Fox - Zukunft Pink',
      full: 'Peter Fox - Zukunft Pink',
      img: img9,
      video: video9,
      url: 'https://player.vimeo.com/video/761994425',
      // client: 'Peter Fox - Zukunft Pink',
      director: 'Jakob Grunert',
      services: 'VFX, CGI',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'Overwatch 2',
      full: 'Overwatch 2 - Unleash Hope',
      img: img10,
      video: video10,
      url: 'https://player.vimeo.com/video/845509712',
      client: 'Blizzard',
      director: 'BRTHR',
      services: 'VFX, CGI, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'BMW XM',
      full: 'BMW XM',
      img: img4,
      video: video4,
      url: 'https://player.vimeo.com/video/755182377',
      client: 'BMW',
      director: 'BRTHR',
      services: 'VFX, CGI, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Galeries Lafayette',
      full: 'le Grand Magasin de Tous',
      img: img12,
      video: video12,
      url: 'https://player.vimeo.com/video/754686240',
      client: 'Galeries Lafayette',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'PinkPantheress ft. Willow - Where You Are',
      full: 'PinkPantheress ft. Willow - Tell Where You Are',
      img: img13,
      video: video13,
      url: 'https://player.vimeo.com/video/712909417',
      // client: 'PinkPantheress ft. Willow - Where You Are',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'HIMIA Showreel 2022',
      full: 'HIMIA VFX showreel 2022',
      img: img15,
      video: video15,
      url: 'https://player.vimeo.com/video/811839110',
      client: 'HIMIA Showreel 2022',
      director: 'HIMIA Showreel 2022',
      services: 'VFX, Animation',
      category: 'REELS'
    },
    {
      name: 'Grimes - Shinigami Eyes',
      full: 'Grimes - Shinigami Eyes',
      img: img16,
      video: video16,
      url: 'https://player.vimeo.com/video/675519711',
      // client: 'Grimes - Shinigami Eyes',
      director: 'BRTHR',
      services: 'VFX, Animation',
      category: 'MUSIC VIDEO'
    }
  ]

  const handleMouseEnter = (id) => {
    setHoveredId(id)
  };

  const handleMouseLeave = () => {
    setHoveredId(null)
  };

  const filteredWorks = () => {
    if (currentCategory === 'ALL') {
      return works
    }

    return works.filter((work) => work.category === currentCategory)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className='portfolio'>
        <div className='container'>
          <div className="row works-title">
            <div className="col-12 col-md-6">
              <h1>WORKS</h1>
            </div>
            <div className="col-12 col-md-6">
              <ul>
                {categories.map(function (category, i) {
                  return <li key={i} className={category === currentCategory ? 'li-active' : ''} onClick={() => { setCurrentCategory(category) }} >
                    {`${category} ${i === categories.length - 1 ? '' : '/'}`}
                  </li>
                })}
              </ul>
            </div>
          </div>
          <div className='works-cont row'>
            {filteredWorks().map(function (work, i) {
              return <div key={i} className='col-12 col-md-6 work-parent'>
                <div className='work-cont'>
                  <div
                    className='image-cont'
                    onClick={() => meta.showVideo(work)}
                    onMouseEnter={() => handleMouseEnter(work.name)}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={() => handleMouseEnter(work.name)}
                    onTouchEnd={handleMouseLeave}
                  >
                    <img
                      className='work-img'
                      src={work.img}
                      alt={work.url}
                    />
                    {isDesktop && hoveredId === work.name ? (
                      <video
                        className='work-img'
                        src={work.video}
                        autoPlay
                        loop
                        muted
                      />
                    ) : (
                      null
                    )}
                    <div
                      className='mask-cont'
                      style={{
                        opacity: isDesktop && hoveredId === work.name ? 1 : 0,
                      }}
                    >
                      <div className='corner1'></div>
                      <div className='corner2'></div>
                      <div className='title'>
                        {/* <h3>VIDEO {i + 1}:</h3> */}
                        <p>{work.name}</p>
                      </div>
                      <div className='play'>
                        <img src={play} alt='play' />
                        <span>CLICK TO PLAY</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Portfolio
