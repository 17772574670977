import React, { useState } from 'react'
import '../../assets/shared/recentWorks.css'
import img1 from '../../images/portfolio/MOSES_preview.jpg'
import img5 from '../../images/portfolio/COACHELLApreview.png'
import img14 from '../../images/portfolio/asics_x_bentgablenits_preview.jpg'
import img17 from "../../images/portfolio/updates/Dua_X_Jennie_poster_16x9.jpg"
import img18 from "../../images/portfolio/updates/LV_Mura_poster_16x9.jpg"
import img19 from "../../images/portfolio/updates/SIA_poster.jpg"
import seeAllBtn from '../../images/portfolio/see_all.png';

import video1 from '../../videos/moses_preview.mp4'
import video5 from '../../videos/coachellaXyoutube_preview.mp4'
import video14 from '../../videos/asics_preview.mp4'
import video17 from "../../videos/updates/Dua_X_Jennie_preview_16x9.mp4"
import video18 from "../../videos/updates/LV_Mura_preview_16x9.mp4"
import video19 from "../../videos/updates/SIA_preview.mp4"
import meta from "../../store/meta"
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import play from '../../images/shared/showreel/play.svg'
import { isDesktop } from 'react-device-detect'

export const RecentWorks = observer(() => {
  const works = [
    {
      name: 'David Guetta & Sia - Beautiful People',
      full: 'David Guetta & Sia - Beautiful People',
      img: img19,
      video: video19,
      url: 'https://player.vimeo.com/video/1065989688',
      director: ' Daniel Askill',
      services: ' VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'JENNIE & Dua Lipa - Handlebars',
      full: 'JENNIE & Dua Lipa - Handlebars',
      img: img17,
      video: video17,
      url: 'https://player.vimeo.com/video/1064446401',
      director: ' BRTHR',
      services: ' VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'MNNK Bro. (Takashi Murakami & JP THE WAVY) - LV MURAKAMI',
      full: 'MNNK Bro. (Takashi Murakami & JP THE WAVY) - LV MURAKAMI',
      img: img18,
      video: video18,
      url: 'https://player.vimeo.com/video/1065991979',
      director: ' BRTHR',
      services: ' VFX, CGi, Animation',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'Asics x Bentgablenits - A Prize In Every Box',
      full: 'Asics x Bentgablenits - A Prize In Every Box',
      img: img14,
      video: video14,
      url: 'https://player.vimeo.com/video/984466984',
      client: 'Asics, Bentgablenits',
      director: ' Connor Brashier & Angelo Nitsopoulos',
      services: ' VFX, Animation',
      category: 'COMMERCIALS'
    },
    {
      name: 'Moses Sumney - Vintage',
      full: 'Moses Sumney - Vintage',
      img: img1,
      video: video1,
      url: 'https://www.youtube.com/embed/CQdFOdyTGgc',
      client: 'Moses Sumney - Vintage',
      director: 'Moses Sumney',
      services: 'VFX',
      category: 'MUSIC VIDEO'
    },
    {
      name: 'COACHELLA x YouTube',
      full: 'COACHELLA On YouTube',
      img: img5,
      video: video5,
      url: 'https://player.vimeo.com/video/938556780',
      client: 'Coachella, YouTube',
      director: 'BRTHR',
      services: 'VFX, CGI, Animation',
      category: 'COMMERCIALS'
    }
  ]
  const [hoveredId, setHoveredId] = useState(null)
  const navigate = useNavigate()

  const handleMouseEnter = (id) => {
    setHoveredId(id)
  };

  const handleMouseLeave = () => {
    setHoveredId(null)
  };

  return (
    <>
      <div className='recent-works container'>
        <div className='title-recent col'>
          <h2>RECENT</h2>
          <button onClick={() => navigate('/work')} className='see-all'><img src={seeAllBtn} alt="seeallbtn" /></button>
        </div>

        <div className='works-cont row'>
          {works.map(function (work, i) {
            return <div key={i} className='col-12 col-md-6 work-parent'>
              <div className='work-cont'>
                <div
                  className='image-cont'
                  onClick={() => meta.showVideo(work)}
                  onMouseEnter={() => handleMouseEnter(work.name)}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={() => handleMouseEnter(work.name)}
                  onTouchEnd={handleMouseLeave}
                >
                  <img
                    className='work-img'
                    src={work.img}
                    alt={work.url}
                  />
                  {isDesktop && hoveredId === work.name ? (
                    <video
                      className='work-img'
                      src={work.video}
                      autoPlay
                      loop
                      muted
                    />
                  ) : (
                    null
                  )}
                  <div
                    className='mask-cont'
                    style={{
                      opacity: isDesktop && hoveredId === work.name ? 1 : 0,
                    }}
                  >
                    <div className='corner1'></div>
                    <div className='corner2'></div>
                    <div className='title'>
                      {/* <h3>VIDEO {i + 1}:</h3> */}
                      <p>{work.name}</p>
                    </div>
                    <div className='play'>
                      <img src={play} alt='play' />
                      <span>CLICK TO PLAY</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
        <div className='see-all-btn-container'>
          <button onClick={() => navigate('/work')} className='see-all-mob'><img src={seeAllBtn} alt="seeallbtn" /></button>
        </div>
      </div>
    </>
  )
})
